import React from 'react';
import Grid from '@mui/material/Grid';
import { useTheme, makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chiffres from '../component/chiffreImg/Bandeau-chiffres-cles.webp';

const useStyles = makeStyles(theme => ({
	chiffres: {
        backgroundImage: "url(" + Chiffres + ")", 
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },

    anchor:{
        [theme.breakpoints.between('xs','xl')]: {
			display: 'block',
			position: 'relative',
			top: '-15vh',
			visibility: 'hidden',
        }
    }
}));

const EnChiffre = () => {     
	const classes = useStyles();
	const theme = useTheme();
    const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('xs')); 

	return(
		<div className={classes.chiffres}>  
			<div className={classes.anchor} id="chiffres"></div>
			<Grid container>  
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<h1 style={{color: "white", fontSize: responsiveDeviceMobile ? 22 : 32, fontWeight: "bold"}}>GS'O en chiffres c'est déjà plus de...</h1>
				</Grid>         
				<Grid item container xs={12} sm={12} md={12} lg={12}>
					<Grid item xs={12} sm={4} md={4} lg={4}> 
						<p style={{color: "#f7ad0d", fontSize: responsiveDeviceMobile ? 40 : 45, fontWeight: "bold", margin: 0}}>38</p>
						<p style={{color: "white", fontSize: responsiveDeviceMobile ? 20 : 24, fontWeight: "normal", marginTop: 0}}>collectivités <br/> utilisatrices</p>
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={4}> 
						<p style={{color: "#f7ad0d", fontSize: responsiveDeviceMobile ? 40 : 45, fontWeight: "bold", margin: 0}}>4 000</p>
						<p style={{color: "white", fontSize: responsiveDeviceMobile ? 20 : 24, fontWeight: "normal", marginTop: 0}}>communes <br/> couvertes</p>
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={4}> 
						<p style={{color: "#f7ad0d", fontSize: responsiveDeviceMobile ? 40 : 45, fontWeight: "bold", margin: 0}}>7</p>
						<p style={{color: "white", fontSize: responsiveDeviceMobile ? 20 : 24, fontWeight: "normal", marginTop: 0}}>miillions d'habitants <br/> couverts</p>
					</Grid>
				</Grid>   
			</Grid>
		</div>
	)
}
export default EnChiffre