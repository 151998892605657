import React, { useEffect, useCallback } from 'react';
import CookieConsent, { getCookieConsentValue} from "react-cookie-consent";
import { initGA } from "./ga-utils.ts";
import './App.css';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import Navigation from './template/navigation.jsx';
import Video from './template/video.jsx';
import Services from './template/services.jsx';
import EnChiffre from './template/enChiffre.jsx';
import Partenaires from './template/partenaires.jsx';
import Presentation from './template/presentation.jsx';
import Footer from './template/footer.jsx';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
		backgroundColor: "white"
    }
}));

const theme = createTheme();

const App = () => {
	const classes = useStyles();
	const location =  window.location.href;

	const handleAcceptCookie = useCallback(() => {
		if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
			initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID,location);
		}
	}, [location]);

	useEffect(() => {
		const isConsent = getCookieConsentValue();
		if (isConsent === "true") {
			handleAcceptCookie();
		}
	}, [handleAcceptCookie]);	

	return (
		<ThemeProvider theme={theme}>
			<div className={classes.root}>
				<div id="content">
					<Navigation />
					<Video />
					<Services />
					<EnChiffre />
					<Partenaires />
					<Presentation />
				</div> 
				<Footer />
				<CookieConsent
					location="bottom"
					enableDeclineButton
					buttonText="Accepter"
					declineButtonText="Refuser"
					buttonStyle={{
					background: "#69aec4",
					color: "white",
					fontWeight: "bolder",
					}}
					onAccept={handleAcceptCookie}
				>
					Ce site Web utilise des cookies pour améliorer l'expérience utilisateur.
				</CookieConsent>
			</div>
		</ThemeProvider>

	);
}

export default App;
