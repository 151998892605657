import React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Partenaire from "../component/partenairesImg/partenaires.webp"
import PartenaireResponsive from "../component/partenairesImg/partenaires-responsive.webp"
import Confiance from "../component/partenairesImg/confiance.webp"
import ConfianceResponsive from "../component/partenairesImg/confiance-responsive.webp"

const useStyles = makeStyles(theme => ({
	partenaires: {
		[theme.breakpoints.up('xs')]: {
			"& img": {
				width: "90%"
			}
		}
	}
}))

const Partenaires = () => {
	const classes = useStyles();
	const theme = useTheme();
    const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('xs')); 

	return (
		<div className={classes.partenaires}>
			<div className={classes.anchor} id="partenaires"></div>
			<Grid container justifyContent="center"> 
				<Grid item xs={12} sm={12} md={12} lg={12}> 
					<h1 style={{color: "#006388", fontSize: 30, fontWeight: "bold", marginBottom:"5vh"}}>Partenaires</h1>
				</Grid>      
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<img src={responsiveDeviceMobile ? PartenaireResponsive : Partenaire} alt="Partenaires" />
				</Grid>      
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<h1 style={{color: "#006388", fontSize: 25, fontWeight: "normal", fontStyle: "italic", marginTop: "10vh", marginBottom:"5vh"}}>Ils nous font confiance</h1>
				</Grid>      
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<img src={responsiveDeviceMobile ? ConfianceResponsive : Confiance} alt="Ils nous font confiance" />
				</Grid>      
			</Grid>
		</div>
	)
}
export default Partenaires