import React from 'react';
import Nav from '../component/navigationcomponent'
import { makeStyles, useTheme } from '@mui/styles';
import { styled } from "@mui/material/styles";
import Section1 from '../template/section1.jsx';
import Logo from '../component/navigationImg/logo-gso.svg'

import PersonIcon from '@mui/icons-material/Person';
import {Button, Grid} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import BtnDemo from '../component/navigationImg/BtnDemo.svg';
import BackgroundSection1 from '../component/section1Img/Header.webp'

const useStyles = makeStyles(theme => ({
	section1: {  
		backgroundImage: "url(" + BackgroundSection1 + ")", 
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		color: "white",
	},
	root: {
		paddingTop: 30,
		position: "relative",
		margin: 0,
		height: 500,
	},
	logo:{
		width: 140,
	},
	itemNav: {
		color: "white",
		fontWeight: "bold",
		fontSize: 20, 
		margin: "0px 15px",
		padding: 5,
	},
	headerLink: {
		color: 'white',
		textTransform: 'uppercase',
		backgroundColor: 'rgba(0 , 69 , 99 , 0.89)',
		width: '100%',
		position: "absolute",
		bottom: 0,
	},
	headerAnchor: {
		zIndex: 1,
		boxShadow: '0 -4px 10px -2px rgba(0,0,0,0.74)',
		paddingTop: '1rem',
		paddingBottom: '1rem',
		textAlign: "center",
	},
		navigation:{  
		backgroundImage: "url(" + BackgroundSection1 + ")", 
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: "white",
    },
	anchor:{
        [theme.breakpoints.between('xs','xl')]: {
			display: 'block',
			position: 'relative',
			top: '-15vh',
			visibility: 'hidden',
        }
    }
}));

const StyledButton = styled(Button)({
	background: "#f7ad0d",
	borderRadius: 5,
	color: "white",
	width: "100%",
	height: "100%",
	textAlign: "center",
	fontSize: 20
});

const Navigation = () => {
	const classes = useStyles();
	const theme = useTheme();
	const responsiveDeviceDesktop = useMediaQuery(theme.breakpoints.up('lg'));

	const mailTo = 'https://meet.sendinblue.com/espelia-demo?t=0&l=gs-o';

	return (
		<div className={classes.section1}>
			{responsiveDeviceDesktop ?
				<div className={classes.desktop}>
					<Nav/>    
					<Section1 mailTo={mailTo} BtnDemo={BtnDemo} />
				</div>
			: 
				<Grid container direction="column" className={classes.root}>
					<Grid item container justifyContent="center" alignItems="center" xs={12}>
						<a href='#video'><img src={Logo} alt='logo' className={classes.logo}></img></a>
					</Grid>
					<Grid item xs={12} sm={12} md={12}> 
						<h1 style={{fontSize: 25, fontWeight: "bold"}}>Pilotez vos services d'eau et d'assainissement</h1>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						<h2 style={{fontSize: 20, fontWeight: "normal"}}>
								Une solution conçue par et pour les collectivités locales
								pour faciliter au quotidien la gestion de leurs services
						</h2> 
						<Grid item xs={12} justifyContent="center" alignItems="center" className={classes.boutonDemo} >
							<a href={mailTo} style={{ textDecoration: "none" }} target="_blank" rel=" noopener noreferrer">
								<img src={BtnDemo} alt="demande démo"></img>
							</a>
						</Grid>
					</Grid>
					<Grid container alignContent='center' alignItems='center' className={classes.headerLink}>
						<Grid item xs={6} className={classes.headerAnchor}>Présentation</Grid>
						<Grid item xs={6} className={classes.headerAnchor}>Fonctionnalités</Grid>
						<Grid item xs={6} className={classes.headerAnchor}>Partenaires</Grid>
						<Grid item xs={6} className={classes.headerAnchor}>Qui sommes-nous</Grid>
						<Grid item xs={12} style={{backgroundColor: "#f7ad0d", fontWeight: "bold"}}>
							<StyledButton startIcon={<PersonIcon style={{ fontSize: 30 }} />} href="https://gso.espedata.fr/">Se connecter</StyledButton>
						</Grid>
					</Grid>
				</Grid> 
			}
		</div>
	)
}
  export default Navigation
  