import React from 'react';
import Grid from '@mui/material/Grid';
import { useTheme, makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ContactImg from '../component/contactImg/contactImg'

const useStyles = makeStyles(theme => ({
	presentation:{       
        background: "linear-gradient(90deg, #66aec8, #006388)",
        marginTop:"6vh",
    }, 
}));

const Presentation = () => {  
    const classes = useStyles();
    const theme = useTheme();
	const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('xs')); 
    
    return (
        <div className={classes.presentation}> 
            <div className={classes.anchor} id="contact"></div>
            <Grid container justifyContent="center" style={{color: "white"}}>  
                <Grid item xs={10} sm={9} md={9} lg={9} style={{color: "white"}}>       
                    <h1 style={{fontSize: responsiveDeviceMobile ? 22 : 32, fontWeight: "bold"}}>Qui sommes-nous ?</h1>   
                    <h2 style={{fontSize: responsiveDeviceMobile ? 18 : 20, fontWeight: "bold"}}>ESPELIA, LA RÉFÉRENCE DU CONSEIL POUR LA PERFORMANCE PUBLIQUE</h2>   
                    <p style={{fontSize: responsiveDeviceMobile ? 18 : 18, fontWeight: "normal"}}>
                    Fondé en 1995, Espelia est aujourd’hui le cabinet leader du conseil en gestion des services publics. Fort de près de 200
                    collaborateurs, le cabinet conseille les collectivités, les ministères et leurs établissements publics, en France
                    comme à l’international.
                    </p>
                </Grid>  
                <Grid item container xs={9} sm={9} md={9} lg={9} style={{color: "white"}} >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p style={{fontSize: responsiveDeviceMobile ? 20 : 30, fontWeight: "normal", color: "#f7ad0d", padding: 20,  borderBottom: "2px solid #f7ad0d"}}>Vos contacts</p> 
                    </Grid>
                    <ContactImg />

                </Grid>      
            </Grid>
        </div>

    )
} 

export default Presentation

