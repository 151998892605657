import React from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Plaquette from "../component/plaquette/PlaquetteGSO.pdf"
import BtnConsultez from "../component/plaquette/BtnConsultez.svg"

const useStyles = makeStyles(theme => ({
    video: { 
		[theme.breakpoints.down('xs')]: {
			marginTop: "3vh"    
		},
		[theme.breakpoints.up('sm')]: {
			marginTop: "5vh"    
		}
    },
}));

const Video = () => {
    const classes = useStyles();

    const theme = useTheme();
    const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const responsiveDeviceDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <div className={classes.video}>
            <div className={classes.anchor} id="presentation"></div>
            <h1 style={{ color: "#006388", fontSize: responsiveDeviceMobile ? 22 : 30, fontWeight: "bold" }}>Présentation</h1>
            <p style={{ width: responsiveDeviceDesktop ? "75vw" : "85vw", margin: "auto", fontSize: 18 }}>
                Conçu par et pour les collectivités locales compétentes en eau et en assainissement, GS'O est une plateforme vouée à faciliter au quotidien l’exercice de leurs missions d’autorité organisatrice.
                GS’O permet en effet une <strong>centralisation et capitalisation optimisée des données</strong> grâce à la saisie unique des données. La <strong>gestion de l’ensemble du cadre contractuel</strong> est <strong>facilitée</strong>, notamment avec le module de suivi des engagements contractuels et le calcul automatique des actualisations et tarifs. L’analyse et la <strong>transmission des données annuelles vers SISPEA</strong> est aussi automatisée tout comme la production des RPQS. La <strong>valorisation et suivi de votre patrimoine</strong> est possible dans GS’O avec une gestion des inventaires patrimoniaux et des plans prévisionnels de renouvellement automatiques. Les <strong>tableaux de bords interactifs</strong> permettent une gestion dynamique des indicateurs de performance. GS’O est une solution moderne, full web et accessible depuis n’importe quel appareil avec une connexion internet. Elle simplifie et facilité le quotidien des agents qui gèrent les services d’eau et d’assainissement.
            </p>
            <div style={{ width: responsiveDeviceDesktop ? 560 : "95%", margin: "auto", marginTop: "5vh", marginBottom: "5vh" }}>
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                    <iframe src="https://www.youtube.com/embed/Xon6_GW9-GM" title="gso" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', frameborder: "0", borderRadius: 15, border: "1px solid #006388", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }} allowFullScreen></iframe>
                </div>
            </div>
            <div id="img-container" style={{ margin: 'auto', width: "18rem", height: "auto" }}>
                <a href={Plaquette} style={{ textDecoration: "none", color: "#f7ad0d", fontWeight: "bold", fontSize: 22 }} target="_blank" rel=" noopener noreferrer">
                    <img src={BtnConsultez} alt="notre plaquette commerciale" style={{ width: "100%", height: "100%" }}></img>
                </a>
            </div>
        </div>
    )
}
export default Video


