import React from "react";
import {Grid, Typography} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Img1 from './serviceImg/fonctionnalites-1.webp';
import Img2 from './serviceImg/fonctionnalites-2.webp';
import Img3 from './serviceImg/fonctionnalites-3.webp';

const useStyles = makeStyles(theme => ({
    centralisation: {
        marginTop: "5vh",
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
        [theme.breakpoints.between('sm', 'md')]: {
            margin: 0,

            "& img":{
                width: "100%"
            }
        },
        "& h3": {
            [theme.breakpoints.up('xs')]: {
                color: "#f7ad0d", 
                fontSize: 25, 
                fontWeight: "bold", 
                borderBottom: "1px solid #f7ad0d", 
                textAlign: "right", 
                width: 750, 
                marginLeft: "-425px",            
            },
            [theme.breakpoints.down('xs')]: {
                border: "none",
                fontSize: 18, 
                margin: 0,
                width: "auto",  
                marginTop: "1vh"
            },
        },
        "& p": {
            [theme.breakpoints.up('xs')]: {
                textAlign: "left", 
                fontSize: 18
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: "center", 
                fontSize: 16,
                margin: "2vh 0",
            },
        }
    },
    analyse: {
        marginTop: "5vh",
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
        [theme.breakpoints.between('sm', 'md')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
        "& h3": {
            [theme.breakpoints.up('xs')]: {
                color: "#f7ad0d", 
                fontSize: 25, 
                fontWeight: "bold", 
                borderBottom: "1px solid #f7ad0d", 
                textAlign: "left", 
                width: 625, 
                marginRight: "-380px", 
                zIndex: 1    
            },
            [theme.breakpoints.down('xs')]: {
                border: "none",
                fontSize: 18, 
                margin: 0,
                width: "auto", 
                marginTop: "1vh"
            },
        },
        "& p": {
            [theme.breakpoints.up('xs')]: {
                textAlign: "right", 
                fontSize: 18            
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: "center", 
                fontSize: 16,
                margin: "2vh 0",
            },
        },
        
    },
    visualisation: {
        marginTop: "5vh",
        [theme.breakpoints.down('xs')]: {
            margin: 0,
            "& img":{
                width: "100%",
            }
        },
        [theme.breakpoints.between('sm', 'md')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
        "& h3": {
            [theme.breakpoints.up('xs')]: {
                color: "#f7ad0d", 
                fontSize: 25, 
                fontWeight: "bold", 
                borderBottom: "1px solid #f7ad0d", 
                textAlign: "right", 
                width: 750, 
                marginLeft: "-375px"            
            },
            [theme.breakpoints.down('xs')]: {
                border: "none",
                fontSize: 18, 
                margin: 0,
                width: "auto", 
                marginTop: "1vh",
            },
        },
        "& p": {
            [theme.breakpoints.up('xs')]: {
                textAlign: "left", 
                fontSize: 18
            },
            [theme.breakpoints.down('xs')]: {
                textAlign: "center", 
                fontSize: 16,
                margin: "2vh 0",
                // marginBottom: ""
            },
        }
    },
}))


export default function ServicesComponent() {
    const theme = useTheme();
    const classes = useStyles();
    const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('xs'));
	const responsiveDeviceDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <>
            <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent="center" spacing={responsiveDeviceDesktop ? 6 : 2} className={classes.centralisation}>
                <Grid container item justifyContent={responsiveDeviceMobile ? "center" : "flex-end"} alignItems="center" xs={12} sm={6} md={6} lg={6}>
                    <img alt="icon" src={Img1} />
                </Grid>
                <Grid container item direction="column" justifyContent={responsiveDeviceMobile ? "center" :"flex-start"} alignItems={responsiveDeviceMobile ? "center" : "flex-start"} xs={12} sm={6} md={6} lg={6}>
                    <h3>Centralisation des données</h3>
                    <Typography>
                        Patrimoine <br />
                        Données annuelles d’exploitation <br />
                        Documents contractuels <br />
                        Tarifs 
                    </Typography>
                </Grid>  
            </Grid>  
            <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="center" spacing={responsiveDeviceDesktop ? 6 : 2} className={classes.analyse}>
                {responsiveDeviceMobile ? 
                    <>
                        <Grid container item justifyContent="center" alignItems="center" xs={12}>
                            <img alt="icon" src={Img2} />
                        </Grid>
                        <Grid container item direction="column" justifyContent="center" alignItems="center" xs={12}>
                            <h3>Analyse et expertise</h3>
                            <Typography>
                                Indicateurs de performance <br />
                                Coefficients d’actualisation et prix de l’eau <br />
                                Plans prévisionnels de renouvellement <br />
                                Veille sectorielle et juridique <br />
                            </Typography>
                        </Grid>  
                    </>
                :
                    <>
                        <Grid container direction="column" item justifyContent="flex-start" alignItems="flex-end" xs={6} sm={6} md={6} lg={6}>
                            <h3>Analyse et expertise</h3>
                            <Typography>
                                Indicateurs de performance <br />
                                Coefficients d’actualisation et prix de l’eau <br />
                                Plans prévisionnels de renouvellement <br />
                                Veille sectorielle et juridique <br />
                            </Typography>
                        </Grid>  
                        <Grid container item justifyContent="flex-start" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <img alt="icon" src={Img2}></img>
                        </Grid>
                    </>
                }   
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="center" spacing={responsiveDeviceDesktop ? 6 : 2} className={classes.visualisation}>
                <Grid container item justifyContent={responsiveDeviceMobile ? "center" : "flex-end"} alignItems="center" xs={12} sm={6} md={6} lg={6}> 
                    <img alt="icon" src={Img3} />
                </Grid>
                <Grid container direction="column" item justifyContent={responsiveDeviceMobile ? "center" :"flex-start"} alignItems={responsiveDeviceMobile ? "center" : "flex-start"} xs={12} sm={6} md={6} lg={6}>
                    <h3>Visualisation et communication</h3>
                    <Typography>
                        RPQS personnalisés <br />
                        Tableaux de bord intéractifs <br />
                        Échanges avec SISPEA<br />
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
