import React from 'react';
import {Grid} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	backgroundSection: {
		height: 300,
		[theme.breakpoints.down('md')]: {
			height: 500,
		},
		[theme.breakpoints.down('xs')]: {
			height: 600,
		},
	},
	boutonDemo: {
		marginTop: 15,
        textAlign: "center",
        margin: 'auto',
        width: "25%",
        height: "auto"
    },
}));
  
const Section1 = ({mailTo, BtnDemo}) => {
	const classes = useStyles();

	return (
		<Grid 
			id="back-to-top-anchor"
			container item
			justifyContent="center" alignItems="center"
			className={classes.backgroundSection}
		>
			<Grid item xs={12} sm={12} md={12} style={{textAlign:"center"}}>
				<h1>PILOTEZ VOS SERVICES D'EAU ET D'ASSAINISSEMENT</h1>
			</Grid>
			<Grid item xs={12} sm={12} md={12} style={{textAlign:"center"}}>
				<h2 style={{textAlign:"center"}}>
					Une solution conçue par et pour les collectivités locales <br />
					pour faciliter au quotidien la gestion de leurs services
				</h2> 
			</Grid>
			<Grid item className={classes.boutonDemo}>
		 		<a href={mailTo} style={{ textDecoration: "none" }} target="_blank" rel=" noopener noreferrer">
		 			<img src={BtnDemo} alt="demande démo"></img>
		 		</a>
		 	</Grid>
		</Grid>    
	)
}

export default Section1