import React from 'react';
import { useTheme, makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Grid, IconButton, Tooltip }from '@mui/material';
import { LinkedIn as LinkedInIcon } from '@mui/icons-material';

import LogoEspelia from './footerImg/logo_espelia.svg';
import LogoGroupe from './footerImg/logo_groupe-baseline_blanc.svg';
import LogoRcf from './footerImg/logo_rcf.svg';
import LogoTecurbis from './footerImg/logo_tecurbis.svg';

const useStyles = makeStyles(theme => ({
    footerdiv: { 
        backgroundColor: '#4B4644',
    },
    divLogoGroupe: {
        marginTop: 20,
        marginBottom: 15,
        "& img": {
            width: "100%"
        },       
        [theme.breakpoints.down('md')]: {
            width: "6.5rem",
        },
        [theme.breakpoints.up('sm')]: {
            width: "12.75rem",
        },
    },
    divBandeauLogo: {
        "& img": {
            width: "100%"
        },
        [theme.breakpoints.up('sm')]: {
            width: "6.5rem",
        },
        [theme.breakpoints.down('md')]: {
            width: "3.5rem",
        },    
    },
    divLogoFiliere: {
        paddingTop: 15,
        paddingBottom: 20,
    },
    subFooter: {
        color: '#424242',
        backgroundColor: '#DADADA !important',
        fontSize: 14, 
        fontWeight: "bold",
        textAlign:'center', 
        "& p": {
            margin: 0
        }
    }
}))
  
export default function Footer() {
    const classes = useStyles();
    const theme = useTheme();
    const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div id="footer" className={classes.footerdiv}>
            <Grid container justifyContent="center" alignItems="center">
                <div className={classes.divLogoGroupe}>
                    <img src={LogoGroupe} alt="Logo du groupe Espelia avec une écriture blanche" />
                </div>
            </Grid>
            <Grid container alignItems="center" className={classes.divLogoFiliere}>
                <Grid container item xs={4} sm={4} md={4} lg={4} justifyContent="flex-end">
                    <div className={classes.divBandeauLogo}>
                        <a href="https://www.espelia.fr" target="_blank" rel="noopener noreferrer">            
                            <img src={LogoEspelia} alt="Logo du groupe Espelia avec une écriture blanche" />
                        </a>
                    </div>
                </Grid>
                <Grid container item xs={4} sm={4} md={4} lg={4} justifyContent="center">
                    <div className={classes.divBandeauLogo}>
                        <a href="https://www.ressources-consultants-finances.fr" target="_blank" rel="noopener noreferrer">            
                            <img src={LogoRcf} alt="Logo du groupe Espelia avec une écriture blanche" />
                        </a>
                    </div>
                </Grid>
                <Grid container item xs={4} sm={4} md={4} lg={4} justifyContent="flex-start">
                    <div className={classes.divBandeauLogo}>
                        <a href="https://tecurbis.fr/" target="_blank" rel="noopener noreferrer">            
                            <img src={LogoTecurbis} alt="Logo du groupe Espelia avec une écriture blanche" />
                        </a>
                    </div>
                </Grid>
            </Grid>
            <Grid container justifyContent={responsiveDeviceMobile ? "center" : "space-around"} alignItems="center" className={classes.subFooter}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <p>&copy; GS’O - {new Date().getFullYear()} Espelia SAS  - <a href="https://www.espelia.fr/"> www.espelia.fr </a></p>
                </Grid>
                {!responsiveDeviceMobile && 
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <IconButton href="https://www.linkedin.com/company/espelia/" target="_blank" rel="noopener noreferrer">
                            <Tooltip title="linkedin">   
                                <LinkedInIcon fontSize="small" style={{ color: "#006388", margin: 0 }} /> 
                            </Tooltip>
                        </IconButton>
                    </Grid>
                }
            </Grid> 
        </div>  
    );
}